/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/anchor-is-valid */
import logo from './logo.svg';
import { useState } from 'react';
import { useMutation } from 'react-query';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { getInTouch, trialAccount } from './apis/mutation';
import { useBlogs, useFetchAppLink } from './apis/queries';
import { useEffect, useMemo } from 'react';
import { Snackbar, Alert } from '@mui/material';
import { DD_MM_YYYY } from './utils/functions';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
// Mui
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { getDataFromStorage, setDataInStorage, setEncryptionKey } from './utils/localStorage';

const owlItemStyle = {
  width: '227.4px'
}

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<>&#11162;</>}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function App() {
  const [open, setOpen] = useState(false);
  const [like, setLike] = useState(getDataFromStorage('like-helpdice-business'))

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    setEncryptionKey('account_key', new Date().getTime());
  }, [])

  const [expanded, setExpanded] = useState('panel1');

  const handleWatchChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };



  const [openModal, setOpenModal] = useState(false);

  const handleClickOpen = () => {
    setOpenModal(true);
  };
  const handleModalClose = () => {
    setOpenModal(false);
  };

  const [openWatch, setOpenWatch] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleWatchOpen = () => {
    setOpenWatch(true);
  };

  const handleWatchClose = () => {
    setOpenWatch(false);
  };


  const { mutate: trialAccountMutation } = useMutation(trialAccount, {
    onSuccess: (response) => {
      document.getElementById('user-email').value = '';
      // console.log(response);
      setOpen({
        message: response?.data?.message ?? response?.message,
        type: "success"
      });
    },
    onError: (err) => {
      // console.log(err);
      setOpen({
        message: err?.response?.data?.message ?? err?.message,
        type: "error"
      });
      // console.log("error", err.response?.data?.message);
    }
  });
  const { mutate: getInTouchMutation } = useMutation(getInTouch, {
    onSuccess: (data) => {
      const msg = data.message;
      document.getElementById("contactForm").reset();
      setOpen({
        message: msg,
        type: "success"
      });
    },
    onError: (error) => {
      const msg = error.response ? error.response.data.message || error.toString() : error.toString();
      setOpen({
        message: "Something Went Wrong!",
        type: "error"
      });
    }
  })


  const handleLike = () => {
    setDataInStorage('like-helpdice-business', true);
    setLike(true);
  }

  const handleDislike = () => {
    setDataInStorage('like-helpdice-business', false);
    setLike(false);
  }

  const { data: _blogs, isLoading: isBlogsLoading, isError: isBlogsError } = useBlogs();

  const BLOGS = useMemo(() => {
    let listOfBlogs = [];
    if (!isBlogsLoading && !isBlogsError) {
      if (_blogs?.pages?.length > 0) {
        _blogs.pages.forEach((group) => {
          listOfBlogs = group.blogs
        });
      }
    }
    return listOfBlogs;
  }, [_blogs, isBlogsLoading, isBlogsError]);

  const { data: app, isLoading: isAppLoading, isError: isAppError } = useFetchAppLink('HELPDICE-01');

  // useEffect(() => {
  //   function timeout() {
  //       setTimeout(function () {
  //         const device = document.getElementById("device");
  //         if(device.classList.contains("desk")){
  //             device.classList.remove("desk");
  //             device.classList.add("laptop");
  //           }else if(device.classList.contains("laptop")){
  //             device.classList.remove("laptop");
  //             device.classList.add("tablet");
  //           }else if(device.classList.contains("tablet")){
  //             device.classList.remove("tablet");
  //             device.classList.add("phone");
  //           }else if(device.classList.contains("phone")){
  //             device.classList.remove("phone");
  //             device.classList.add("desk");
  //           }
  //           timeout();
  //       }, 4000);
  //     }
  //     timeout();
  // }, [])


  const features = [
    {
      color: "#00C853",
      headline: "Ai Roster",
      text: "Having a rostering ai engine lets you take over the control on your crucials decisions",
      icon: "fas fa-project-diagram",
      mdDelay: "0",
      smDelay: "0",
    },
    {
      color: "#6200EA",
      headline: "Attendance",
      text: "Check daily and monthly attendance of users",
      icon: "far fa-chart-bar",
      mdDelay: "200",
      smDelay: "200",
    },
    {
      color: "#0091EA",
      headline: "Alerts and Notifications",
      text: "For not missing an event or updates, we prefer relevant realtime alerts and notifications",
      icon: "far fa-bell",
      mdDelay: "400",
      smDelay: "0",
    },
    {
      color: "#d50000",
      headline: "Event Management",
      text: "Making last minute plan or event isn't easy before, but now our event manager takes away all bothering",
      icon: "far fa-calendar-alt",
      mdDelay: "0",
      smDelay: "200",
    },
    {
      color: "#DD2C00",
      headline: "Housing",
      text: "Having a need of managing housing facilities for users, our product will handle this for you",
      icon: "fas fa-home",
      mdDelay: "200",
      smDelay: "0",
    },
    {
      color: "#64DD17",
      headline: "Inventory",
      text: "Managing a lots of product isn't easy, use our inventory to do it for you",
      icon: "fas fa-boxes",
      mdDelay: "400",
      smDelay: "200",
    },
    {
      color: "#304FFE",
      headline: "Invoicing / Billing",
      text: "Worry about product getting out of stock, we handle this for you every time you create a invoice",
      icon: "fas fa-file-invoice",
      mdDelay: "0",
      smDelay: "0",
    },
    {
      color: "#C51162",
      headline: "Live Tracking",
      text: "Track each users entered into our ecosystem",
      icon: "fas fa-map-marked-alt",
      mdDelay: "200",
      smDelay: "200",
    },
    {
      color: "#00B8D4",
      headline: "Payroll",
      text: "Calculating payouts for each employee is a tedious task, so we made it automated",
      icon: "fas fa-money-check",
      mdDelay: "400",
      smDelay: "0",
    },
    {
      color: "#00B8D4",
      headline: "Remote meeting",
      text: "Remote meeting required lot of resources, we make sure your they are conducted smoothly from our end",
      icon: "fas fa-laptop",
      mdDelay: "400",
      smDelay: "0",
    },
    {
      color: "#00B8D4",
      headline: "Scheduler",
      text: "Unusual timing of work or 24 * 7, schedule timing for each users effortlessly",
      icon: "fas fa-clipboard-list",
      mdDelay: "400",
      smDelay: "0",
    },
    {
      color: "#00B8D4",
      headline: "Storage",
      text: "For sharing files with your team, we attach the Nvme storage unit with outstanding read and write speed",
      icon: "fas fa-hdd",
      mdDelay: "400",
      smDelay: "0",
    },
    {
      color: "#00B8D4",
      headline: "Team Chats",
      text: "Having team accessible alongside, it very fluent to communicate information",
      icon: "fas fa-comments",
      mdDelay: "400",
      smDelay: "0",
    },
  ];

  return (
    <div className="App">
      <header id="home" className="hero-area2">
        <div className="overlay-light"></div>
        <div className="navbar-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav className="navbar navbar-expand-lg">
                  <a className="navbar-brand" href="#">
                    <img src="/static/logo.png" width={80} alt="Logo" />
                  </a>
                  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                  </button>
                  <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                    <ul id="nav" className="navbar-nav ml-auto">
                      <li className="nav-item active">
                        <a className="page-scroll" href="/">Home</a>
                      </li>
                      <li className="nav-item">
                        <a className="page-scroll"
                          href="#about">About</a>
                      </li>
                      <li className="nav-item">
                        <a className="page-scroll"
                          href="#client-talk">Talks</a>
                      </li>
                      <li className="nav-item">
                        <a className="page-scroll"
                          href="#pricing2">Pricing</a>
                      </li>
                      {/* <li className="nav-item">
                        <a className="page-scroll" href="#team2">Team</a>
                      </li> */}
                      <li className="nav-item">
                        <a className="page-scroll" href="#blog2">Blog</a>
                      </li>
                      <li className="nav-item">
                        <a className="page-scroll"
                          href="#contact2">Contact</a>
                      </li>
                      {/* <li className="nav-item">
                        <a className="btn btn-light-singin" href="https://app.helpdice.com">Sign
                          In</a>
                      </li> */}
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div id="home">
          <div className="container">
            <div className="row space-100 align-items-center">
              <div className="col-lg-6 col-md-12 col-xs-12">
                <div className="contents" sx={{ textAlign: 'left !important' }}>
                  <h2 className="head-title">Future of Business <br className="d-none d-md-block" /> With Us!</h2>
                  <p>Lets us serve you and make your business <br />
                  operation easy for the future.</p>
                  <Formik
                    initialValues={{
                      email:'',
                      submit: null
                    }}
                    validationSchema={
                      Yup.object().shape({
                        email: Yup.string().required('Email is required'),
                    })}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
                        try {
                            setStatus({ success: false });
                            setSubmitting(false);
                            // trialAccountMutation({
                            //   email: values.email,
                            //   trial: true,
                            // });
                            getInTouchMutation({
                              name: 'New Lead',
                              email: values.email,
                              msg_subject: 'I want to try your product',
                              phone: '0000000000',
                              message: 'I want to explore your product',
                              submit: null
                            });
                            document.getElementById("get-started-form").reset();
                        } catch (err) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }}
                  >
                    {({ handleSubmit, handleChange }) => {
                      return (
                        <form id="get-started-form" onSubmit={handleSubmit} className="subsribe-input">
                          <div className="form-group form-subsribe">
                            <input id="user-email" onChange={handleChange} type="email" className="form-control" name="email" placeholder="Enter Your Email" />
                            <button type="submit" className="btn btn-light-bg btn-subsribe">Get Started</button>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                  <p className="para">*No Credit Card Required</p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-xs-12 p-0">
                <div className="intro-img">
                  {/* <img src="/static/intro.png" className="img-fluid" alt="" /> */}
                  {/* <div id="device" className="desk">
                    <span></span>
                    <div className="contCube responsive-iframe">
                    <iframe title="desk" src="http://127.0.0.1:3000" frameBorder="0" allowFullScreen></iframe>
                     <iframe title="" src="https://player.vimeo.com/video/60814695?title=0&amp;byline=0&amp;portrait=0&amp;color=ffffff" width="448" height="252" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
                    </div>
                  </div> */}
                  <div className="macbook">
                    <div className="screen">
                      <div className="viewport">
                      </div>
                    </div>
                    <div className="base"></div>
                    <div className="notch"></div>
                  </div>

                  {/* <div className="object-laptop">
                    <div className="screen">
                      <div className="lcd">
                      <img alt="" src="/static/desktop.png" />
                      </div>
                      <div className="gloss"></div>
                      <div className="reflexion"></div>
                      <div className="highlight one"></div>
                      <div className="highlight two"></div>
                    </div>
                    <div className="keyboard one"></div>
                    <div className="keyboard two"></div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* <section id="brand-area">
        <div className="container">

          <div className="row">

            <div className="col-lg-12">
              <div className="brand-subtitle">
                <h5>Powering the world's most innovative companies</h5>
              </div>
            </div>

          </div>


          <div className="row">

            <div className="col-lg-3 col-sm-6">
              <div className="brand-item text-center">
                <img src="/static/01.jpg" className="img-fluid" alt="" />
              </div>
            </div>


            <div className="col-lg-3 col-sm-6">
              <div className="brand-item text-center">
                <img src="/static/02.jpg" className="img-fluid" alt="" />
              </div>
            </div>


            <div className="col-lg-3 col-sm-6">
              <div className="brand-item text-center">
                <img src="/static/03.jpg" className="img-fluid" alt="" />
              </div>
            </div>


            <div className="col-lg-3 col-sm-6">
              <div className="brand-item text-center">
                <img src="/static/04.jpg" className="img-fluid" alt="" />
              </div>
            </div>

          </div>

        </div>
      </section> */}

      <section id="arrow-area">
        <div className="container">

          <div className="row">

            <div className="col-lg-12">
              <div className="arrow-icon text-center active">
                <a href="##features2" className="page-scroll"><span><i
                      className="fa fa-arrow-down"></i></span></a>
              </div>
            </div>

          </div>

        </div>
      </section>

      <section id="light-featured">
        <div className="container">

          <div className="row">

            <div className="col-xl-5 col-lg-6 col-md-12">

              <div className="light-feature-item">
                <div className="light-feature-icon float-left">
                  <i className="fas fa-robot fa-2x"></i>
                </div>
                <div className="light-feature-info float-left">
                  <h4>Ai Powered</h4>
                  <p>Our Algorithms predits <br /> presents realtime insights</p>
                </div>
              </div>

              <div className="light-feature-item">
                <div className="light-feature-icon float-left">
                  <i className="fas fa-swatchbook fa-2x"></i>
                </div>
                <div className="light-feature-info float-left">
                  <h4>Trendy Design</h4>
                  <p>Well designed things<br /> always catches eyes.</p>
                </div>
              </div>


              <div className="light-feature-item">
                <div className="light-feature-icon float-left">
                  <i className="far fa-id-badge fa-2x"></i>
                </div>
                <div className="light-feature-info float-left">
                  <h4>Easy to Use</h4>
                  <p>Goal to make our <br /> users unstoppable.</p>
                </div>
              </div>

            </div>

            <div className="col-xl-7 col-lg-6 col-md-12 pr-0">
              <div className="right-feature-info">
                <h3>Starting with Helpdice Business is easier than
                  anything!</h3>
                <p>Signup with the email, Choose your desirable plan based upon the features offered, get the payments done.
                  All Sets, You are onBoard. Following the instructions on screen for further asistance, or you can reach us out any time.</p>
                <a onClick={handleWatchOpen} className="btn btn-light-bg m-2"><i className="fas fa-play"></i>&ensp;Watch Video</a>
                <a onClick={handleClickOpen} className="btn btn-light-bg m-2">Join Get Along Program</a>
              </div>
            </div>

          </div>

        </div>
      </section>

      <section className="features card py-3 mb-5">
      <br />
      <br />
      <br />
      <h3 style={{ textAlign: 'center' }} id="about-helpdice" className="my-3">Outstanding Features</h3>
      <br />
      <br />
        <div id="gallery" className="container-fluid p-2">  
          {features.map((feature) => (
            <figure key={feature.headline} className="snip1519">
              <figcaption>
                <i className={`${feature.icon}`}></i>
                <h3>{feature.headline}</h3>
                <p>{feature.text}</p>
              </figcaption>
            </figure>
          ))}
        </div>
        <br />
        <br />
      </section>

      <section id="light-featured" className="pt-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <h3 id="about-helpdice" className="my-3">About</h3>
              <p style={{ textAlign: 'justify', fontSize: 15  }}>
                Founder of Helpdice, find trouble in managing operation within a company. So, he decided to build something for
                organising his operations and to keep an eye on whats going on all the time. Then came the idea of building a system
                that can help him managing the company and save time to processess things faster and efficient. After few months of the system in action
                he realize that many single owner company are faing these issues, so the founder decided it to be made available for all the business owners
                of any kind small to big have a great companign along side them.
              </p>
            </div>
            <div id="why-helpdice-business" className="col-lg-6 col-md-12">
              <h3 className="my-3">Why Helpdice Business ?</h3>
              <p style={{ textAlign: 'justify', fontSize: 15  }}>
                Helpdice Business is provided to all its client with eitics and rules. It is delivered by the coordinate distribution channel as stated by its founder.
                No one in the company can can go beyond this code of conduct not even the founder. Helpdice Business also provide private server. Helpdice ensures security and entigrity
                of its both clients and system. Offers a versitility of request any feature even you are on free trial no extra charges are taken, all the costs
                encured in building requested feature are in care of Helpdice itself. 
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="light-featured" className="pt-0">
        <div className="container">
          <div className="row">
            <div id="helpdice-security" className="col-lg-6 col-md-12">
              <h3 className="my-3">Security</h3>
              <p style={{ textAlign: 'justify', fontSize: 15  }}>
                Helpdice is secure to use with latest encryptions one inact server with distributed branches.
                Helpdice Business is a system of computers connected in a distributed network, so that each request are handled seperately wihtout the interference from other.
                Helpdice also provide private servers for their client on demand. Helpdice processes data with end to end encryption. Security of the this system relies on 
                user permissions and access codes. So, be carefull when dividing permissions and providing access codes.
              </p>
            </div>
            <div id="helpdice-customer-service" className="col-lg-6 col-md-12">
              <h3 className="my-3">Customer Service</h3>
              <p style={{ textAlign: 'justify', fontSize: 15  }}>
                Before building this system, we conducted a survey on different software or service that provide what helpdice business is providing, but we found nothing was good
                to said truly, Every system have some flaus either in service or in management. So, we decided to make it best for every customer, listen to their business individually
                and providing the isolated solution to each respectives. After using helpdice business they dont't have to go look anywhere else. Our most of the customers are extremely
                energetic everyday with the work style they abopt with out product we named as "Helpdice Business".
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="light-featured" className="pt-0">
        <div className="container">
          <h3 className="my-3" style={{ textAlign: 'center' }}>Why we aren't on Play Store or App Store ?</h3>
          <p style={{ textAlign: 'justify', fontSize: 15  }}>
            Helpdice wants be an independent company without any kind of enfluence over it. So, we decided to stay isolated and our service and pricing not get
            biased due to extra charges that will be incurred on us or our customer. This Hepdice fair use of policy help our customers a lot for relevant
            pricing without inhibiting excessive or incossistent price hikes. 
          </p>
        </div>
      </section>

      <section id="download-app">
        <div className="container">

          <div className="row">

            <div className="col-lg-6 col-md-12">
              <div className="download-info">
                <h3>Download Our Application for Platform of choice</h3>
                <p>Accessibility and Availability are key for the business, keeping in our energetic minds we extends our limits to most popular stores in the era of technology to change the future of business worldwide.</p>
                {!isAppLoading && !isAppError ? (
                  <div className="downlaod-btn">
                    <a href={app?.link} className="btn play-store"><i
                        className="fab fa-android"></i>Get It On Android <br /> <span>Download</span></a>
                    <a href={app?.ilink} className="btn ios-store" onClick={() => alert("Coming Soon...")}><i
                        className="fab fa-app-store"></i>Get It On iOS<br /> <span> Download</span></a>
                  </div>
                ) : null}
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <div className="download-statatics">
                    <h3>100K +</h3>
                    <p>Downloads</p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="download-statatics">
                    <h3>2K +</h3>
                    <p>Follower</p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="download-statatics">
                    <h3>300 +</h3>
                    <p>Reviews</p>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-lg-1">
            </div>


            <div className="col-lg-5 col-md-12">
              <div className="mokup-thumb">
                <img src="/static/mockup2.png" className="img-fluid" alt="" />
              </div>
            </div>

          </div>

        </div>
      </section>

      <section id="features2" className="section">
        <div className="container">

          <div className="row">
            <div className="col-lg-12">
              <div className="features-text section-header text-center">
                <div>
                  <h2 className="section-title">Built With All Cool Features</h2>
                  <div className="desc-text">
                    <p>For us, Our customers needs is the biggest feature. <br className="d-none d-lg-block" />
                      they ask we built, then a new feature is born.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="" className="row featured-bg">
            <div className="col-lg-6 col-md-6 col-xs-12 p-0">
              <div className="feature-item featured-border1">
                <div className="feature-icon float-left">
                  <i className="fab fa-intercom"></i>
                </div>
                <div className="feature-info float-left">
                  <h4>Easy to Customize</h4>
                  <p>Feeling of own home, where we put 
                    <br className="d-none d-lg-block" /> things as we want. Same way we offers 
                    <br className="d-none d-lg-block" /> something similar, we understand that's your.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-xs-12 p-0">
              <div className="feature-item featured-border2">
                <div className="feature-icon float-left">
                  <i className="fab fa-themeco fa-2x"></i>
                </div>
                <div className="feature-info float-left">
                  <h4>Central Ecosystem</h4>
                  <p>We are all together on mission, whenever
                    <br className="d-none d-lg-block" /> you use what we offer you feels the same
                    <br className="d-none d-lg-block" /> all connected ass one.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-xs-12 p-0">
              <div className="feature-item featured-border1">
                <div className="feature-icon float-left">
                  <i className="fas fa-pencil-ruler"></i>
                </div>
                <div className="feature-info float-left">
                  <h4>Clean &amp; Trendy Design</h4>
                  <p>Everthing loves clean and trendy lifestyle
                    <br className="d-none d-lg-block" /> So, while considering
                    that, we provide<br className="d-none d-lg-block" /> you with same.
                  </p>
                </div>
              </div>

            </div>


            <div className="col-lg-6 col-md-6 col-xs-12 p-0">

              <div className="feature-item featured-border2">
                <div className="feature-icon float-left">
                  <i className="fas fa-layer-group"></i>
                </div>
                <div className="feature-info float-left">
                  <h4>Tons of Sections</h4>
                  <p>When we put similar things in own place
                    <br className="d-none d-lg-block" /> its best, we can get it easily
                    decade,<br className="d-none d-lg-block" /> Same way we put similar feature together.
                  </p>
                </div>
              </div>

            </div>


            <div className="col-lg-6 col-md-6 col-xs-12 p-0">

              <div className="feature-item featured-border3">
                <div className="feature-icon float-left">
                  <i className="fas fa-magic fa-2x"></i>
                </div>
                <div className="feature-info float-left">
                  <h4>Free Future Updates</h4>
                  <p>All the feature updates as per the certain
                    <br className="d-none d-lg-block" /> section in our ecosystem are free for
                    <br className="d-none d-lg-block" /> lifetime.
                  </p>
                </div>
              </div>

            </div>


            <div className="col-lg-6 col-md-6 col-xs-12 p-0">

              <div className="feature-item">
                <div className="feature-icon float-left">
                  <i className="fas fa-life-ring fa-2x"></i>
                </div>
                <div className="feature-info float-left">
                  <h4>Premier Support</h4>
                  <p>Support being charm to the identity of a person,
                    <br className="d-none d-lg-block" /> while shaking the same hand, we enhance future
                    <br className="d-none d-lg-block" /> identity to your business.
                  </p>
                </div>
              </div>

            </div>

          </div>

        </div>
      </section>


      <section id="screenshots" className="section">
        <div className="container-fluid">

          <div className="row">
            <div className="col-lg-12">
              <div className="team-text section-header text-center">
                <div>
                  <h2 className="section-title">Beautiful Screens</h2>
                  <div className="desc-text">
                    <p>Getting a glimpse of the ui, before installing means a lot
                      <br className="d-none d-md-block" />so we are sharing some of the designs
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row pl-70 pr-70">
            <div className="touch-slider owl-carousel owl-loaded owl-drag">
              <div className="owl-stage-outer">
                <div className="owl-stage"
                  style={{ transition: 'all 0s ease 0s', width: '4549px', transform: 'translate3d(455px, 0px, 0px)'}}>
                  <div className="owl-item active center" style={owlItemStyle}>
                    <div className="item">
                      <div className="screenshot-thumb">
                        <img className="img-fluid" src="/static/Screen_1.png" alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="owl-item active" style={owlItemStyle}>
                    <div className="item">
                      <div className="screenshot-thumb">
                        <img className="img-fluid" src="/static/Screen_2.png" alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="owl-item active" style={owlItemStyle}>
                    <div className="item">
                      <div className="screenshot-thumb">
                        <img className="img-fluid" src="/static/Screen_3.png" alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="owl-item active" style={owlItemStyle}>
                    <div className="item">
                      <div className="screenshot-thumb">
                        <img className="img-fluid" src="/static/Screen_4.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="owl-nav disabled">
                <button type="button" role="presentation" className="owl-prev">
                  <span aria-label="Previous">‹</span>
                </button>
                <button type="button" role="presentation" className="owl-next">
                  <span aria-label="Next">›</span>
                </button>
              </div>
              <div className="owl-dots">
                <button role="button" className="owl-dot active">
                  <span></span>
                </button>
                <button role="button" className="owl-dot"><span></span></button><button role="button"
                  className="owl-dot"><span></span></button><button role="button" className="owl-dot"><span></span></button><button
                  role="button" className="owl-dot"><span></span></button><button role="button"
                  className="owl-dot"><span></span></button><button role="button" className="owl-dot"><span></span></button><button
                  role="button" className="owl-dot"><span></span></button><button role="button"
                  className="owl-dot"><span></span></button><button role="button" className="owl-dot"><span></span></button><button
                  role="button" className="owl-dot"><span></span></button><button role="button"
                  className="owl-dot"><span></span></button><button role="button" className="owl-dot"><span></span></button><button
                  role="button" className="owl-dot"><span></span></button><button role="button"
                  className="owl-dot"><span></span></button><button role="button" className="owl-dot"><span></span></button><button
                  role="button" className="owl-dot"><span></span></button><button role="button"
                  className="owl-dot"><span></span></button><button role="button" className="owl-dot"><span></span></button><button
                  role="button" className="owl-dot"><span></span></button>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section id="pricing2" className="section">
        <div className="container">

          <div className="row">
            <div className="col-lg-12">
              <div className="pricing-text section-header text-center">
                <div>
                  <h2 className="section-title">Pricing Plans</h2>
                  <div className="desc-text">
                    <p>Ours plans are for the future of Business
                      <br className="d-none d-md-block" /> with technology.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="row pricing-tables justify-content-center justify-content-md-start">

            <div className="col-lg-4 col-md-4 col-sm-10 col-xs-12">
              <div className="pricing-table text-center">
                <div className="pricing-details">
                  <h3>Drive</h3>
                  <h1><span>$</span>9.99<small style={{ fontSize: 'small' }}>/&nbsp;month</small></h1>
                  <ul>
                    <li>Free Instalation</li>
                    <li>Upto 50 Users</li>
                    <li>Live Tracking</li>
                    <li>Attendance & Scheduling</li>
                    <li>Invoicing & Inventory</li>
                    <li>Housing</li>
                    <li>Payroll</li>
                    <li><s>On Demand Feature</s></li>
                    <li><s>Dedicated Assistance</s></li>
                  </ul>
                </div>
                {/* <div className="plan-button">
                  <a href="##" className="btn btn-border">Purchase</a>
                </div> */}
              </div>
            </div>


            <div className="col-lg-4 col-md-4 col-sm-10 col-xs-12">
              <div className="pricing-table text-center">
                <div className="pricing-details">
                  <h3>standard</h3>
                  <h1><span>$</span>19.99<small style={{ fontSize: 'small' }}>/&nbsp;month</small></h1>
                  <ul>
                    <li>Free Instalation</li>
                    <li>Upto 100 Users</li>
                    <li>Live Tracking</li>
                    <li>Attendance & Scheduling</li>
                    <li>Invoicing & Inventory</li>
                    <li>Housing</li>
                    <li>Payroll</li>
                    <li>On Demand Feature</li>
                    <li><s>Dedicated Assistance</s></li>
                  </ul>
                </div>
                {/* <div className="plan-button">
                  <a href="#" className="btn btn-common">Purchase</a>
                </div> */}
              </div>
            </div>


            <div className="col-lg-4 col-md-4 col-sm-10 col-xs-12">
              <div className="pricing-table text-center">
                <div className="pricing-details">
                  <h3>Business</h3>
                  <h1><span>$</span>29.99<small style={{ fontSize: 'small' }}>/&nbsp;month</small></h1>
                  <ul>
                    <li>Free Instalation</li>
                    <li>Upto 200 Users</li>
                    <li>Live Tracking</li>
                    <li>Attendance & Scheduling</li>
                    <li>Invoicing & Inventory</li>
                    <li>Housing</li>
                    <li>Payroll</li>
                    <li>On Demand Features</li>
                    <li>Dedicated Assistance</li>
                  </ul>
                </div>
                {/* <div className="plan-button">
                  <a href="#" className="btn btn-border">Purchase</a>
                </div> */}
              </div>
            </div>

          </div>

        </div>
      </section>


      <section id="client-talk" className="section">
        <div className="container">

          <div className="row">
            <div className="col-lg-12">
              <div className="team-text section-header text-center">
                <div>
                  <h2 className="section-title">What Our Client Says</h2>
                  <div className="desc-text">
                    <p>Clients builds a business into reality.
                      <br className="d-none d-md-block" /> We offer Equalize priority to each clients. 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="row">

            <div className="col-lg-4 col-md-6 col-xs-12">
              <div className="client-words">
                <p>I Feel much more organised and aware from before when i just started using Helpdice Business, This product have helped me in making great decisions towards my company. I really appreciate their work and support for their client.</p>
                <h5>Deepesh Thakur <br /><span>-Helpdice Customer</span></h5>
                <span className="qoute-icon"><i className="fa fa-quote-left"></i></span>
              </div>
            </div>


            <div className="col-lg-4 col-md-6 col-xs-12">
              <div className="client-words">
                <p>Using Helpdice Business is so easy that my colleagues are happy about. 
                  We saved a lot of time, as i don't have to tell anybody in my team how to use the product. 
                  For early startups its the best.</p>
                <h5>Subhash Rao <br /><span>-Helpdice Customer</span></h5>
                <span className="qoute-icon"><i className="fa fa-quote-left"></i></span>
              </div>
            </div>


            <div className="col-lg-4 col-md-6 col-xs-12">
              <div className="client-words">
                <p>Helpdice Business makes us centralized, people with same skills in whole company can communicate with each other for suggestion on timely oriented results. Our outputs are getting more relevant, Thank you Helpdice</p>
                <h5>Durga Devi <br /><span>-Helpdice Customer</span></h5>
                <span className="qoute-icon"><i className="fa fa-quote-left"></i></span>
              </div>
            </div>

          </div>

        </div>
      </section>


      {/* <section id="team2" className="section">
        <div className="container">

          <div className="row">
            <div className="col-lg-12">
              <div className="team-text section-header text-center">
                <div>
                  <h2 className="section-title">Team Members</h2>
                  <div className="desc-text">
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                      <br className="d-none d-md-block" /> eiusmod tempor incididunt ut labore et dolore.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="row justify-content-md-start justify-content-center">

            <div className="col-lg-3 col-md-6 col-sm-10 col-xs-12">
              <div className="single-team">
                <div className="team-thumb">
                  <img src="/static/01(2).jpg" className="img-fluid" alt="" />
                </div>
                <div className="team-details">
                  <div className="team-social-icons">
                    <ul className="social-list">
                      <li><a href="##"><i
                            className="lni lni-facebook-filled"></i></a></li>
                      <li><a href="##"><i
                            className="lni lni-twitter-filled"></i></a></li>
                      <li><a href="##"><i
                            className="lni lni-google"></i></a></li>
                    </ul>
                  </div>
                  <div className="team-inner text-center">
                    <h5 className="team-title">Patric Green</h5>
                    <p>Lead Designer</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-10 col-xs-12">
              <div className="single-team">
                <div className="team-thumb">
                  <img src="/static/02(2).jpg" className="img-fluid" alt="" />
                </div>
                <div className="team-details">
                  <div className="team-social-icons">
                    <ul className="social-list">
                      <li><a href="##"><i
                            className="lni lni-facebook-filled"></i></a></li>
                      <li><a href="##"><i
                            className="lni lni-twitter-filled"></i></a></li>
                      <li><a href="##"><i
                            className="lni lni-google"></i></a></li>
                    </ul>
                  </div>
                  <div className="team-inner text-center">
                    <h5 className="team-title">Celina D Cruze</h5>
                    <p>Front-end Developer</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-10 col-xs-12">
              <div className="single-team">
                <div className="team-thumb">
                  <img src="/static/03(1).jpg" className="img-fluid" alt="" />
                </div>
                <div className="team-details">
                  <div className="team-social-icons">
                    <ul className="social-list">
                      <li><a href="##"><i
                            className="lni lni-facebook-filled"></i></a></li>
                      <li><a href="##"><i
                            className="lni lni-twitter-filled"></i></a></li>
                      <li><a href="##"><i
                            className="lni lni-google"></i></a></li>
                    </ul>
                  </div>
                  <div className="team-inner text-center">
                    <h5 className="team-title">Daryl Dixon</h5>
                    <p>Content Writer</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-10 col-xs-12">
              <div className="single-team">
                <div className="team-thumb">
                  <img src="/static/04(2).jpg" className="img-fluid" alt="" />
                </div>
                <div className="team-details">
                  <div className="team-social-icons">
                    <ul className="social-list">
                      <li><a href="##"><i
                            className="lni lni-facebook-filled"></i></a></li>
                      <li><a href="##"><i
                            className="lni lni-twitter-filled"></i></a></li>
                      <li><a href="##"><i
                            className="lni lni-google"></i></a></li>
                    </ul>
                  </div>
                  <div className="team-inner text-center">
                    <h5 className="team-title">Mark Parker</h5>
                    <p>Support Engineer</p>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </section> */}


      <section id="blog2" className="section">

        <div className="container">

          <div className="row">
            <div className="col-lg-12">
              <div className="blog-text section-header text-center">
                <div>
                  <h2 className="section-title">Latest Blog Posts</h2>
                  <div className="desc-text">
                    <p>Our Post are truly dedicated to our clients,
                      <br className="d-none d-md-block" /> Check regularly they might help your businesss.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center justify-content-md-start">

          {!isBlogsLoading && !isBlogsError ? (
              BLOGS.map((bl) => {
                // console.log(bl);
                return (
                  <div key={`blog-${bl.id}`} className="col-lg-4 col-md-6 col-sm-10 col-xs-12 blog-item">
                    <div className="blog-item-wrapper">
                      <div className="blog-item-img">
                        <a href="https://preview.uideck.com/items/slick/app/single-post.html">
                          <img src="/static/01(3).jpg" className="img-fluid" alt="" />
                        </a>
                      </div>
                      <div className="blog-item-text">
                        <h3><a href="https://preview.uideck.com/items/slick/app/single-post.html">{bl.title}</a></h3>
                        <p> {bl.desc.substring(0, 220).concat('...')}</p>
                        <a href="#" className="read-more">5 Min read</a>
                      </div>
                      <div className="author">
                        <span className="name"><i className="fa fa-user"></i><a
                            href="##">Posted by Admin</a></span>
                        <span className="date float-right"><i className="fa fa-calendar"></i><a
                            href="##">{DD_MM_YYYY(bl.date_published)}</a></span>
                      </div>
                    </div>
                  </div>
                )
              })
          ) : null}

          </div>

        </div>
      </section>


      <section id="contact2" className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="contact-text section-header text-center">
                <div>
                  <h2 className="section-title">Get In Touch</h2>
                  <div className="desc-text">
                    <p>Want to future drive your businesss with us,
                      <br className="d-none d-md-block" /> Fill the form and Get the call within 24 Hour
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">

            <div className="col-lg-6 col-md-12 col-xs-12">
              <Formik
                initialValues={{
                  name: '',
                  email:'',
                  msg_subject: '',
                  phone: '',
                  message: '',
                  submit: null
                }}
                validationSchema={
                  Yup.object().shape({
                    name: Yup.string().required("Name is required"),
                    email: Yup.string().required('Email is required'),
                    msg_subject: Yup.string().required("Subject is required"),
                    phone: Yup.string().required("Phone is required"),
                    message: Yup.string().required("Message is required")
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        setStatus({ success: false });
                        setSubmitting(false);
                        getInTouchMutation(values);
                    } catch (err) {
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                    }
                }}
              >
                {({ handleChange, handleSubmit, errors }) => {
                  return (
                    <form onSubmit={handleSubmit} id="contactForm" noValidate>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <input onChange={handleChange} type="text" className="form-control" id="name" name="name" placeholder="Name" required=""
                              data-error="Please enter your name" />
                            <div className="help-block with-errors">
                              {errors?.name}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input 
                              onChange={handleChange} 
                              type="text" 
                              placeholder="Subject" 
                              id="msg_subject" 
                              className="form-control" 
                              name="msg_subject"
                              required="" data-error="Please enter your subject" />
                            <div className="help-block with-errors">
                              {errors?.msg_subject}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input 
                              onChange={handleChange} 
                              type="text" 
                              className="form-control" 
                              id="email" 
                              name="email" 
                              placeholder="Email"
                              required=""
                              data-error="Please enter your Email" />
                            <div className="help-block with-errors">
                              {errors?.email}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input onChange={handleChange} type="text" placeholder="Phone" id="phone" className="form-control" name="phone" required=""
                              data-error="Please enter your phone" />
                            <div className="help-block with-errors">
                              {errors?.phone}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <textarea onChange={handleChange} className="form-control" id="message" name="message" placeholder="Write Message" rows="4"
                              data-error="Write your message" required=""></textarea>
                            <div className="help-block with-errors">
                              {errors?.message}
                            </div>
                          </div>
                          <div className="submit-button">
                            <button className="btn btn-common disabled" id="submit" type="submit"
                              style={{ pointerEvents: 'all', cursor: 'pointer' }}>Submit</button>
                            <div id="msgSubmit" className="h3 hidden"></div>
                            <div className="clearfix"></div>
                          </div>
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>


            <div className="col-lg-1">
            </div>


            <div className="col-lg-4 col-md-12 col-xs-12">
              <div className="contact-img">
                <img src="/static/02.png" className="img-fluid" alt="" />
              </div>
            </div>


            <div className="col-lg-1">
            </div>

          </div>
        </div>
      </section>


      <footer>
        <section id="footer-Content2">
          <div className="container">

            <div className="row">

              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-mb-12">
                <div className="footer-logo">
                  <img src="/static/logo.png" width="150" alt="Logo" />
                  <p>A Team that improve constantly to provide great service to their customers</p>
                  <ul className="footer-social-list">
                    <li><a href="https://www.facebook.com/people/Helpdice/100090491326455/"><i
                          className="fab fa-facebook"></i></a></li>
                    {/* <li><a href="#"><i
                          className="fab fa-twitter"></i></a></li> */}
                    <li><a href="https://www.instagram.com/helpdice/"><i
                          className="fab fa-instagram"></i></a></li>
                    <li><a href="https://www.linkedin.com/company/helpdice"><i
                          className="fab fa-linkedin"></i></a></li>
                  </ul>
                </div>
              </div>


              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-mb-12">
                <div className="widget">
                  <h3 className="block-title">Product</h3>
                  <ul className="menu">
                    <li><a href="#about-helpdice">- About</a></li>
                    <li><a href="#pricing2">- Price</a></li>
                    <li><a href="#helpdice-security">- Security</a></li>
                    <li><a href="#hepdice-customer-service">- Customer Service</a></li>
                    <li><a href="#why-helpdice-business">- Why Helpdice Business?</a></li>
                  </ul>
                </div>
              </div>


              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-mb-12">
                <div className="widget">
                  <h3 className="block-title">Download</h3>
                  <ul className="menu">
                    <li><a href={app?.link}>- Android</a></li>
                    <li>
                      <a href={app?.ilink}>- iOS</a>
                      <a href="#" style={{ marginLeft: 12, lineHeight: '14px', fontSize: '14px', color: 'black' }} className="badge badge-light">Coming Soon</a>
                    </li>
                    <li>
                      <a href={app?.wlink}>- Windows</a>
                      <a href="#" style={{ marginLeft: 12, lineHeight: '14px', fontSize: '14px', color: 'black' }} className="badge badge-light">
                        Coming Soon
                      </a>
                    </li>
                  </ul>
                </div>
              </div>


              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-mb-12">
                <div className="widget">
                  <h3 className="block-title">Contact</h3>
                  <ul className="footer-address-info">
                    <li className="d-none"><i className="fa fa-address-book" aria-hidden="true"></i>Remote</li>
                    <li><i className="fa fa-phone"></i>+91 75800 11245</li>
                    <li><i className="fas fa-envelope"></i>sales@helpdice.com</li>
                  </ul>
                </div>
              </div>

            </div>

          </div>

          <div className="copyright">
            <div className="container">

              <div className="row">
                <div className="col-md-12">
                  <div className="site-info text-center">
                    <p>All Rights Reserved &copy; 2019 - 2022&nbsp;<a href="https://helpdice.com/" rel="nofollow">Helpdice</a></p>
                  </div>
                </div>

              </div>

            </div>
          </div>

        </section>
      </footer>


      <a href="/" className="home2-scroll back-to-top" style={{ display: 'none' }}>
        <i className="fa fa-chevron-up"></i>
      </a>

      <div className="floating_btn">
        <a target="_blank" href="https://wa.me/+917580011245">
          <div className="contact_icon">
            <i className="fab fa-whatsapp my-float"></i>
          </div>
        </a>
        <p className="text_icon">Talk to us?</p>
      </div>

      <div id="preloader" style={{ display: 'none' }}>
        <div className="loader" id="loader-1"></div>
      </div>
      <Snackbar open={!!open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={open?.type} sx={{ width: '100%' }}>
          {open?.message}
        </Alert>
      </Snackbar>
      <Dialog
        sx={{ zIndex: 9999 }}
        onClose={handleModalClose}
        aria-labelledby="customized-dialog-title"
        open={openModal}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Join Get Along Program
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleModalClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            px: 2.5,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          x
        </IconButton>
        <DialogContent dividers>
        <Typography>Get Upto 30% Discount</Typography>
        <br />
        <ol>
          <li>This program states you will be enrolling as beta user of our product 
within our company. </li>
<li>You can provide us daily, weekly or monthly feedback for 
improvement, Bug fixes and new feature requests.</li>
<li> No extra charges for, on demand feature request.</li>
<li>As per this program you will be getting access to all the features we 
have developed or are pre-released. </li>
<li>Prioritized Support</li>
<li>Don't worry your data will be backup before each update whenever 
data specific updates are released</li>
        </ol>
        <Typography>How you can join ?</Typography>
        <br />
        <Typography variant="body"><b>Compose mail to :</b> sales@helpdice.com</Typography>
        <br />
        <Typography variant="body"><b>With Subject :</b> Join Get Along Program</Typography>
        </DialogContent>
        <DialogActions>
        
        </DialogActions>
      </Dialog>
      <Dialog
        sx={{ zIndex: 9999 }}
        fullScreen={fullScreen}
        open={openWatch}
        onClose={handleWatchClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Helpdice Business Demo Video"}
          <Typography variant="body2">Have a campaign alongside managing your business</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          <Accordion expanded={expanded === 'panel1'} onChange={handleWatchChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Registeration</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <video src="/static/registeration.mp4" width="100%" height="240" controls />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleWatchChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Initial Setup</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <video src="/static/initial_setup.mp4" width="100%" height="240" controls />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleWatchChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Dashboard Overview</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography align="center">Coming Soon...</Typography>
          {/* <video src="/static/sample.mp4" width="100%" height="240" controls /> */}
        </AccordionDetails>
      </Accordion>
          </DialogContentText>
          <br />
          <Stack justifyContent="left">
            <Typography>1.2K&ensp;<i className="far fa-thumbs-up"></i>&ensp;</Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDislike}>
            {!like && like !== undefined ? <i className="fas fa-thumbs-down"></i> : <i className="far fa-thumbs-down"></i>}&ensp;I don't like this Product
          </Button>
          <Button onClick={handleLike} autoFocus>
            {like ? <i className="fas fa-thumbs-up"></i> : <i className="far fa-thumbs-up"></i>}&ensp;I like this Product
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default App;