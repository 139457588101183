import axios from 'axios';
import API from '../utils/axios';
import { baseApiURL, baseHdURL } from '../utils/config';

export const trialAccount = async (values) => {
    if (values) {
        const { data } = await API.post(`${baseApiURL}/auth/trial`, values);
        return data;
    }
    throw new Error('No data to send');
};

export const getInTouch = async (values) => {
    if (values) {
        const { data } = await axios.post(`${baseHdURL}/lead/`, values);
        return data;
    }
    throw new Error('No data to send');
};