import axios from 'axios';
import { useInfiniteQuery, useQuery } from 'react-query';
import { baseApisURL, baseHdURL } from '../utils/config';

export const fetchBlogs = async (queryParams) => {
    const [,] = queryParams.queryKey;
    const config = {
        params: {
            next: queryParams.pageParam
        }
    };
    const { data } = await axios.get(`${baseHdURL}/get-blogs/business/`, config);
    return data;
};

export const useBlogs = () => {
    return useInfiniteQuery(['blogs'], fetchBlogs, {
        getNextPageParam: (lastPage) => lastPage?.paging?.hasMore && lastPage.paging.next,
        onError: (error) => {
            // status: 401 unauthorized
        }
    });
};

export const fetchAppLink = async (queryParams) => {
    const [, Id] = queryParams.queryKey;
    const config = {
        params: {
            appId: Id
        }
    };
    const { data } = await axios.get(`${baseApisURL}/application/check-updates`, config);
    return data.app;
};

export const useFetchAppLink = (Id) => {
    return useQuery(['app-link', Id], fetchAppLink, {
        onError: (error) => {
            // status: 401 unauthorized
        }
    });
};

