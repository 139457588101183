import axios from 'axios';
import { baseApiURL } from './config';
// import { getDataFromStorage } from './localStorage';

const API = axios.create({ baseURL: baseApiURL });

// API.interceptors.request.use((req) => {
//     if (getDataFromStorage('accountAuthToken')) {
//         req.headers.authorization = `Bearer ${getDataFromStorage('accountAuthToken')}`;
//     }
//     return req;
// });

export default API;